

import { Options, Vue } from 'vue-class-component';
import SparePartsStatusChange from '@/modules/spare_parts/components/SparePartsStatusChange.vue';



@Options({
  components: {
    SparePartsStatusChange,
  },
})

export default class SparePartsStatusChangeView extends Vue {}

