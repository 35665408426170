import router from '@/router';
import { Vue } from 'vue-class-component';
import RmaService from '../../services/rma.service';

const rmaService = new RmaService();
export default class RmaStatusChange extends Vue{
    public current_status = '';
    public statuses: any = [];
    public form: any = {
        rma_status: this.rmaStatus(),

    };
    
    public errorMessage = '';
  

    mounted() {
        this.rmaStatusList();
        this.rmaId();
        this.rmaStatus();
        const status: any = this.$refs.status
        status.focus()
        
      }

      rmaId(){
        if(localStorage.getItem('rma_id') != null){
            return localStorage.getItem('rma_id')
        }else{
            return "Invalid"
        }
        
    }
    
    rmaStatus(){
        if(localStorage.getItem('rma_status') != null){
            return localStorage.getItem('rma_status')
        }else{
            return "Invalid"
        }
    }
        


    rmaStatusList(){
        
        rmaService.DisplayRmaStatus().then(
            (response: any) =>{
                const res = response.data.data;
                this.statuses = res;
                // console.log(this.statuses)    
            }
        )
    }

    changeRmaStatus(){
        this.$store.state.Loading = true;
        const request = this.form
        
        const id = localStorage.getItem('rma_id')
        rmaService.ChangeRmaStatus(id, request).then(
            (response: any) =>{
                this.$store.state.Loading = false;
                const res = response.data;
				// console.log(res)
                router.push('/rma-id-status')
            },(err: any) => {
                this.$store.state.Loading = false;
                this.errorMessage = 'Invalid RMA ID';
                console.log(this.errorMessage)
              }   
        ) 
        
    }

    created() {
        const id = localStorage.getItem('rma_id')
        rmaService.RmaIdCheck(id).then(
            (response: any) =>{
               
                const res = response.data;
                this.current_status = res.data.rma_status
                
                // console.log(this.current_status)
				// console.log(res)
                localStorage.setItem('rma_status', this.current_status)
                this.form.rma_status = this.rmaStatus();
                // console.log(this.rmaStatus());
               
             
            }
        )
    }
    

}