

import { Options, Vue } from 'vue-class-component';
import SparePartsLocationChange from '@/modules/spare_parts/components/SparePartsLocationChange.vue';



@Options({
  components: {
    SparePartsLocationChange,
  },
})

export default class SparePartsLocationChangeView extends Vue {}

