

import { Options, Vue } from 'vue-class-component';
import SparePartsGetIdForEnquiry from '@/modules/spare_parts/components/SparePartsGetIdForEnquiry.vue';



@Options({
  components: {
    SparePartsGetIdForEnquiry,
  },
})

export default class SparePartsGetIdForEnquiryView extends Vue {}

