

import { Options, Vue } from 'vue-class-component';
import RmaStatusChange from '@/modules/rma/components/RmaStatusChange.vue';




@Options({
  components: {
    RmaStatusChange,
  },
})

export default class RmaStatusChangeView extends Vue {}

