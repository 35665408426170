

import { Options, Vue } from 'vue-class-component';
import RmaEnquiry from '@/modules/rma/components/RmaEnquiry.vue';




@Options({
  components: {
    RmaEnquiry,
  },
})

export default class RmaEnquiryView extends Vue {}

