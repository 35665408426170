import router from '@/router';
import { Vue } from 'vue-class-component';
import RmaService from '../../services/rma.service';

const rmaService = new RmaService();
export default class RmaEnquiry extends Vue{
   public current_status = '';
   public current_cage = '';

    rmaId(){
        if(localStorage.getItem('rma_id') != null){
            return localStorage.getItem('rma_id')
        }else{
            return "Invalid"
        }
        
    }
    
    rmaStatus(){
        if(localStorage.getItem('rma_status') != null){
            return localStorage.getItem('rma_status')
        }else{
            return "Invalid"
        }
    }
        
    cageNumber(){
        if(localStorage.getItem('cage_number') != null){
            return localStorage.getItem('cage_number')
        }else{
            return "Invalid"
        }
    }

    created() {
        const id = localStorage.getItem('rma_id')
        rmaService.RmaIdCheck(id).then(
            (response: any) =>{
               
                const res = response.data;
                this.current_status = res.data.rma_status
                this.current_cage = res.data.cage_number
                // console.log(this.current_status)
				// console.log(res)
                localStorage.setItem('rma_status', this.current_status)
                this.form.rma_status = this.current_status;
                this.form.cage_number = this.current_cage;
                // console.log(this.rmaStatus());
               
             
            }
        )
    }
    public form: any = {
        rma_status: this.rmaStatus(),
        cage_number: this.cageNumber(),
    };

    
}