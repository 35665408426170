

import { Options, Vue } from 'vue-class-component';
import RmaGetIdForStatus from '@/modules/rma/components/RmaGetIdForStatus.vue';



@Options({
  components: {
    RmaGetIdForStatus,
  },
})

export default class RmaGetIdForStatusView extends Vue {}

