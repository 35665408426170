
  import { Options, Vue } from 'vue-class-component';
  import CheckUsername from '@/modules/authentication/components/CheckUsername.vue'; // @ is an alias to /src
  
  
  @Options({
    components: {
        CheckUsername,
    },
  })
  export default class CheckUsernameView extends Vue {}
  