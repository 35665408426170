import HomeView from './views/HomeView.vue';


const homeRoutes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    
  },


];

export default homeRoutes;
