import router from '@/router';
import {  Vue } from 'vue-class-component';
import AuthenticationService from '../../services/authentication.service';
  
const authService = new AuthenticationService();
  export default class Login extends Vue {
    

    private form = {
      email: '',
      password: '',

    };
    public errorMessage= '';

  mounted() {
    const password: any = this.$refs.password
    password.focus()
    
  }

    login(){
      this.$store.state.Loading = true;
      this.form.email = (localStorage.getItem('username') || '{}');
      const request = {data: this.form}

      authService.Login(request).then(
        (response: any) => {
        this.$store.state.Loading = false;
          // console.log(response.data.data)
        const res = response.data;
				// console.log(res)
        if(response.status == 200){
          localStorage.setItem('user', JSON.stringify(response.data.data));
          this.$store.state.IsUserLoggedIn = true;
          const User = JSON.parse(localStorage.getItem('user') || '{}');
          this.$store.state.User = User.username;
          // this.$router.push('/dashboard');
          // window.location.href = '/home';
          router.push('/home')
        }else{
          this.errorMessage = 'Invalid username or password1.';
          console.log(this.errorMessage)
        }
        },(err: any) => {
          this.$store.state.Loading = false;
          this.errorMessage = 'Invalid password.';
          console.log(this.errorMessage)
        });
        
    // router.push('/home')}
  }


}
