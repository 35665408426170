

import { Options, Vue } from 'vue-class-component';
import RmaHome from '@/modules/rma/components/RmaHome.vue';



@Options({
  components: {
    RmaHome,
  },
})

export default class RmaHomeView extends Vue {}

