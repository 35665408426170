import { Options,Vue } from 'vue-class-component';
import router from '@/router';
import SpinLoader from '@/modules/SpinLoader.vue';
import { mapState } from 'vuex';


@Options({
  components: {
    SpinLoader,
  },
  computed: { ...mapState({
    Loading: (state: any) => state.Loading,
  }) }
})
export default class App extends Vue{

// public Loading = false;
public IsUserLoggedIn = true;
public logoutTimeout : any;

// mounted() {
       
//   this.username();
  
// }
  
username(){
  return localStorage.getItem('username')
}

mounted() {
  this.username();
  this.startLogoutTimer();
  window.addEventListener("mousemove", this.resetLogoutTimer);
  window.addEventListener('keydown', this.resetLogoutTimer);
  }

  startLogoutTimer() {
    this.logoutTimeout = setTimeout(() => {
      if(this.$store.state.IsUserLoggedIn === true){
        this.logout();
      }
      
    }, 15 * 60000); //logout after 15 min 
  }
  
  resetLogoutTimer() {
    clearTimeout(this.logoutTimeout);
    this.startLogoutTimer();
  }
  
  logout() {
    localStorage.clear();
    this.$store.state.IsUserLoggedIn = false;
    router.push('/')
    console.log("User logged out")
  }
}
    

    
    
// }