import router from '@/router';
import {  Vue, Options } from 'vue-class-component';
import SparePartService from '../../services/spare-parts.service';

const sparepartService = new SparePartService();

export default class SparePartsGetIdForLocation extends Vue{
    
    public container_id = '';
    public errorMessage = '';
    public current_location = '';


    mounted() {
        const container: any = this.$refs.containerId
        container.focus()
      }

    
    checkContainerIdForLocation(){
        this.$store.state.Loading = true;
        const id = this.container_id.replace(/^[0\s]*0+/, '').trim()
        sparepartService.ContainerIdCheck(id).then(
            (response: any) =>{
                this.$store.state.Loading = false;
                const res = response.data;
                this.current_location = res.data.location
				// console.log(res)
                localStorage.setItem('container_id', id)
                localStorage.setItem('current_location', this.current_location)
                router.push('/spare-parts-location-change')
            },(err: any) => {
                this.$store.state.Loading = false;
                this.errorMessage = 'Invalid Container ID';
                console.log(this.errorMessage)
              }
        )
    }

}