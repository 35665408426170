

import { Options, Vue } from 'vue-class-component';
import RmaGetIdForCage from '@/modules/rma/components/RmaGetIdForCage.vue';



@Options({
  components: {
    RmaGetIdForCage,
  },
})

export default class RmaGetIdForCageView extends Vue {}

