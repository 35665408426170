import router from '@/router';
import {  Vue } from 'vue-class-component';
import RmaService from '../../services/rma.service';

const rmaService = new RmaService();
export default class RmaGetIdForStatus extends Vue{
    

    public rma_id = '';
    public errorMessage = '';
    public current_cage = '';
    public current_status = '';
 
    mounted() {
        const rma: any = this.$refs.rmaId
        rma.focus()
      }

    checkRmaIdForStatus(){
        this.$store.state.Loading = true;
        const id = this.rma_id.replace(/^[0\s]*0+/, '').trim()
        rmaService.RmaIdCheck(id).then(
            (response: any) =>{
                this.$store.state.Loading = false;
                const res = response.data;
                this.current_status = res.data.rma_status
				// console.log(res)
                localStorage.setItem('rma_id', id)
                localStorage.setItem('rma_status', this.current_status)
                router.push('/rma-status-change')
            },(err: any) => {
                this.$store.state.Loading = false;
                this.errorMessage = 'Invalid RMA ID';
                console.log(this.errorMessage)
              }
        )
    }
}