import router from '@/router';
import {  Vue } from 'vue-class-component';
import AuthenticationService from '../../services/authentication.service';
  
const authService = new AuthenticationService();
  export default class CheckUsername extends Vue {
    private username = '';
    private usernameForm ={
      email: '',
    }

    public errorMessage= '';

  mounted() {
    const user_name: any = this.$refs.username
    user_name.focus()
    
  }

  submitUsername(){
    this.$store.state.Loading = true;
    this.errorMessage = ''
    const request = {data: this.usernameForm}
    authService.UserExist(request).then(
      (response: any) =>{
        this.$store.state.Loading = false;
        const re = response.data
        // console.log(re)
        if (re.success == 0){
          this.errorMessage = 'IP Not Allowed';
         
        }else{
          this.username = re.data.username
          localStorage.setItem('username', this.username)
          router.push('/password')
        }
          
      },(err: any) => {
        
        this.$store.state.Loading = false;
        this.errorMessage = 'Invalid username';
        console.log(this.errorMessage)
      }
    )

    }

//     login(){
//       this.form.email = this.emailForm.email
//       const request = {data: this.form}

//       authService.Login(request).then(
//         (response: any) => {
//           // console.log(response.data.data)
//         const res = response.data;
// 				console.log(res)
//         if(response.status == 200){
//           localStorage.setItem('user', JSON.stringify(response.data.data));
//           this.$store.state.IsUserLoggedIn = true;
//           const User = JSON.parse(localStorage.getItem('user') || '{}');
//           this.$store.state.User = User.username;
//           // this.$router.push('/dashboard');
//           // window.location.href = '/home';
//           router.push('/home')
//         }else{
          
//           this.errorMessage = 'Invalid email or password1.';
//           console.log(this.errorMessage)
//         }
//         },(err: any) => {
//           this.$store.state.Loading = false;
//           this.errorMessage = 'Invalid password.';
//           console.log(this.errorMessage)
//         });
        
//     // router.push('/home')}
//   }


}
