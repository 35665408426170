

import { Options, Vue } from 'vue-class-component';
import RmaCageChange from '@/modules/rma/components/RmaCageChange.vue';



@Options({
  components: {
    RmaCageChange,
    
  },
  
})

export default class RmaCageChangeView extends Vue {}

