
import { Options, Vue } from 'vue-class-component';
import Home from '@/modules/home/components/HomePage.vue'; // @ is an alias to /src


@Options({
  components: {
    Home,
  },
})
export default class HomeView extends Vue {}
