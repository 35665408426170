import router from '@/router';
import { Options, Vue } from 'vue-class-component';
import SparePartService from '../../services/spare-parts.service';
import Vue3Barcode from 'vue3-barcode';

const sparepartsService = new SparePartService();

@Options({
    components: {
      Vue3Barcode,
    },
  })
  
export default class SparePartsEnquiry extends Vue{
    public current_location = '';
    public current_status = '';
    mounted() {
        
        this.containerId();
  
        
      }

    containerId(){
        if(localStorage.getItem('container_id') != null){
            return localStorage.getItem('container_id')
        }else{
            return "Invalid"
        }
        
    }
    
    sparepartsStatus(){
        if(localStorage.getItem('container_status') != null){
            return localStorage.getItem('container_status')
        }else{
            return "Invalid"
        }
    }
        
    containerLocation(){
        if(localStorage.getItem('container_location') != null){
            return localStorage.getItem('container_location')
        }else{
            return "Invalid"
        }
    }

    created() {
        const id = localStorage.getItem('container_id')
        sparepartsService.ContainerIdCheck(id).then(
            (response: any) =>{
                
                const res = response.data;
                this.current_location = res.data.location
                this.current_status = res.data.status
                // console.log(this.current_cage)
				// console.log(res)
                
                localStorage.setItem('current_location', this.current_location)
                localStorage.setItem('current_status', this.current_status)
                this.form.sparepart_status = this.current_status
                this.form.location = this.current_location
             
            }
        )
    }
    
    public form: any = {
        sparepart_status: this.sparepartsStatus(),
        location: this.containerLocation(),
    };

        printBarcode() {
            const dataToPrint = (this.$refs.barcode as HTMLElement);
            console.log(dataToPrint);
            const newWin = window.open('', 'Print', 'height=600,width=800');
            newWin?.document.write('<html><head><title></title></head>');
            
            newWin?.document.write(dataToPrint.outerHTML);
            // new?Win.document.write(label);
            newWin?.document.write('</body></html>');
            newWin?.document.close();
            newWin?.focus();
            newWin?.print();

      }
}

