import BaseService from './base.service';

export default class RmaService{
    private apiService: any;
    constructor() {
    this.apiService = new BaseService();
    }

    public RmaIdCheck(id: any) {
        return this.apiService.post('mos/mos-rma-filed-update/' + id + '/');
      }

    public ChangeCage(id: any, data: any) {
        return this.apiService.post('mos/mos-rma-filed-update/' + id + '/', data);
      }

    public DisplayRmaStatus() {
        return this.apiService.post('mos/mos-display-rma_status/');
      }

      // mos-change-rma_status
    public ChangeRmaStatus(id: any, data: any) {
        return this.apiService.post('mos/mos-rma-filed-update/' + id + '/', data);
      }

}