

import { Options, Vue } from 'vue-class-component';
import SparePartsHome from '@/modules/spare_parts/components/SparePartsHome.vue';



@Options({
  components: {
    SparePartsHome,
  },
})

export default class SparePartsHomeView extends Vue {}

