

import { Options, Vue } from 'vue-class-component';
import SparePartsGetIdForStatus from '@/modules/spare_parts/components/SparePartsGetIdForStatus.vue';



@Options({
  components: {
    SparePartsGetIdForStatus,
  },
})

export default class SparePartsGetIdForStatusView extends Vue {}

