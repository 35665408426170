import BaseService from './base.service';

export default class SparePartService{
    private apiService: any;
    constructor() {
    this.apiService = new BaseService();
    }

    public ContainerIdCheck(id: any) {
        return this.apiService.post('mos/mos-spare_part-filed-update/' + id + '/');
      }

    public ChangeLocation(id: any, data: any) {
        return this.apiService.post('mos/mos-spare_part-filed-update/' + id + '/', data);
      }

    public DisplaySparePartsStatus() {
        return this.apiService.post('mos/mos-display-spare-part_status/');
      }

    public ChangeSparePartsStatus(id: any, data: any) {
        return this.apiService.post('mos/mos-spare_part-filed-update/' + id + '/', data);
      }

    }


