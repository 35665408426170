
  import { Options, Vue } from 'vue-class-component';
  import LoginPage from '@/modules/authentication/components/LoginPage.vue'; // @ is an alias to /src
  
  
  @Options({
    components: {
      LoginPage,
    },
  })
  export default class AuthenticationView extends Vue {}
  