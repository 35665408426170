

import { Options, Vue } from 'vue-class-component';
import RmaGetIdForEnquiry from '@/modules/rma/components/RmaGetIdForEnquiry.vue';



@Options({
  components: {
    RmaGetIdForEnquiry,
  },
})

export default class RmaGetIdForEnquiryView extends Vue {}

