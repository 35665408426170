import AuthenticationView from './views/AuthenticationView.vue';
import CheckUsernameView from './views/CheckUsernameView.vue';
// import Home from './components/Home.vue';


const authRoutes = [
  {
    path: '/',
    name: 'username',
    component: CheckUsernameView,
    meta:{notLoggedIn:true},
  },

  {
    path: '/password',
    name: 'password',
    component: AuthenticationView,
    meta:{notLoggedIn:true},
  },

];


export default authRoutes;
