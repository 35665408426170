import SparePartsHomeView from '../spare_parts/views/SparePartsHomeView.vue';
import SparePartsGetIdForLocationView from '../spare_parts/views/SparePartsGetIdForLocationView.vue';
import SparePartsLocationChangeView from '../spare_parts/views/SparePartsLocationChangeView.vue';
import SparePartsGetIdForStatusView from '../spare_parts/views/SparePartsGetIdForStatusView.vue';
import SparePartsStatusChangeView from '../spare_parts/views/SparePartsStatusChangeView.vue';
import SparePartsGetIdForEnquiryView from '../spare_parts/views/SparePartsGetIdForEnquiryView.vue';
import SparePartsEnquiryView from '../spare_parts/views/SparePartsEnquiryView.vue';


const sparepartsRouters = [
    {
        path: '/spare-parts',
        name: 'spare-parts',
        component: SparePartsHomeView,
    },

    {
        path: '/spare-parts-id-location',
        name: 'spare-parts-id-location',
        component: SparePartsGetIdForLocationView,
    },


    {
        path: '/spare-parts-location-change',
        name: 'spare-parts-location-change',
        component: SparePartsLocationChangeView,
    },

    {
        path: '/spare-parts-id-status',
        name: 'spare-parts-id-status',
        component: SparePartsGetIdForStatusView,
    },

    {
        path: '/spare-parts-status-change',
        name: 'spare-parts-status-change',
        component: SparePartsStatusChangeView,
    },

    {
        path: '/spare-parts-id-enquiry',
        name: 'spare-parts-id-enquiry',
        component: SparePartsGetIdForEnquiryView,
    },

    {
        path: '/spare-parts-enquiry',
        name: 'spare-parts-enquiry',
        component: SparePartsEnquiryView,
    },
]


export default sparepartsRouters