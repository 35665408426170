import router from '@/router';
import { Vue } from 'vue-class-component';
import SparePartService from '../../services/spare-parts.service';

const sparepartService = new SparePartService();
export default class SparePartsStatusChange extends Vue{
    public current_status = '';
    public statuses: any = [];
    public selected: any;
    public form: any = {
        new_status: this.sparepartStatus(),

    };
    
    public errorMessage = '';
    

    mounted() {
        this.sparepartStatusList();
        this.containerId();
        this.sparepartStatus();
        const status: any = this.$refs.status
        status.focus()
        
      }

      containerId(){
        if(localStorage.getItem('container_id') != null){
            return localStorage.getItem('container_id')
        }else{
            return "Invalid"
        }
        
    }
    
    sparepartStatus(){
        if(localStorage.getItem('current_status') != null){
            return localStorage.getItem('current_status')
        }else{
            return "Invalid"
        }
    }
        


    sparepartStatusList(){
        
        sparepartService.DisplaySparePartsStatus().then(
            (response: any) =>{
                const res = response.data.data;
                this.statuses = res;
                // console.log(this.statuses)    
            }
        )
    }

    changeSparePartsStatus(){
        this.$store.state.Loading = true;
        const request = this.form
        const id = localStorage.getItem('container_id')
        sparepartService.ChangeSparePartsStatus(id, request).then(
            (response: any) =>{
                this.$store.state.Loading = false;
                const res = response.data;
				// console.log(res)
                router.push('/spare-parts-id-status')
            },(err: any) => {
                this.$store.state.Loading = false;
                this.errorMessage = 'Select valid status';
                console.log(this.errorMessage)
              }
        )
        
    }
    
    created() {
        const id = localStorage.getItem('container_id')
        sparepartService.ContainerIdCheck(id).then(
            (response: any) =>{
               
                const res = response.data;
                this.current_status = res.data.status
				// console.log(res)
                
                localStorage.setItem('current_status', this.current_status)
                this.form.new_status = this.sparepartStatus();
                // console.log(this.rmaStatus());
               
             
            }
        )
    }
}