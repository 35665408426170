import { createStore } from 'vuex'

export default createStore({
  state: {
    IsUserLoggedIn: localStorage.getItem('user') ? true : false,
    User: '',
    Loading: false,
    isActive: true,
    loggedInUser: null
  },
  getters: {
  },
  mutations: {
    
  },
  actions: {
  },
  modules: {
  }
})
