import router from '@/router';
import {  Vue, Options } from 'vue-class-component';
import RmaService from '../../services/rma.service';
// import RmaStatus from '../../rma/components/RmaStatus';


const rmaService = new RmaService();

export default class RmaCageChange extends Vue{
    public form = {
        cage_number: '',

    };
    public current_cage = '';
    public errorMessage = '';

    mounted() {
        this.rmaId();
        this.cageNumber();
        const cage: any = this.$refs.cage
        cage.focus()
        
        
      }

      rmaId(){
        if(localStorage.getItem('rma_id') != null){
            return localStorage.getItem('rma_id')
        }else{
            return "Invalid"
        }
        
    }

        
    cageNumber(){
        if(localStorage.getItem('cage_number') != null){
            return localStorage.getItem('cage_number')
        }else{
            return "Invalid"
        }
    }
    
    changeCage(){
        this.$store.state.Loading = true;
        const request = this.form
        const id = localStorage.getItem('rma_id')

        rmaService.ChangeCage(id, request).then(
            (response: any) =>{
                this.$store.state.Loading = false;
                const res = response.data;
				// console.log(res)
                router.push('/rma-id-cage')
            },(err: any) => {
                this.$store.state.Loading = false;
                this.errorMessage = 'Enter valid cage number';
                console.log(this.errorMessage)
              }
        )
        
    }

    created() {
        const id = localStorage.getItem('rma_id')
        rmaService.RmaIdCheck(id).then(
            (response: any) =>{
                this.$store.state.Loading = false;
                const res = response.data;
                this.current_cage = res.data.cage_number
                // console.log(this.current_cage)
				// console.log(res)
                
                localStorage.setItem('cage_number', this.current_cage)
             
            }
        )
    }


}