import { Vue } from 'vue-class-component';
import router from '@/router';

export default class Home extends Vue {

      //   loadModule(num: number) {
      //     if (num === 1) {
      //       router.push('/spare-part')
      //     } else if (num === 2) {
      //       router.push('/rma')
            
      //     } else {
      //       console.error(`Invalid module number: ${num}`);
      //     }
   
      // }

      onKeyDown(event: KeyboardEvent){
        if(event.key === '1'){
          // this.loadModule(2) 
          router.push('/spare-parts') 
          //cl
             
          console.log("working")
        }else if(event.key === '2'){
          // this.loadModule(1)
          // this.$store.state.Loading = true;
          router.push('/rma')
          
        }else if(event.key === 'Enter'){
          router.push('/home')
        }
      }

      mounted() {
          window.addEventListener('keydown', this.onKeyDown)
      }

      beforeUnmount() {
          window.removeEventListener('keydown', this.onKeyDown)
      }

      
}