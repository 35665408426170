import RmaHomeView from '../rma/views/RmaHomeView.vue';
import RmaGetIdForCageView from '../rma/views/RmaGetIdForCageView.vue';
import RmaGetIdForStatusView from '../rma/views/RmaGetIdForStatusView.vue';
import RmaGetIdForEnquiryView from '../rma/views/RmaGetIdForEnquiryView.vue';
import RmaCageChangeView from '../rma/views/RmaCageChangeView.vue';
import RmaStatusChangeView from '../rma/views/RmaStatusChangeView.vue';
import RmaEnquiryView from '../rma/views/RmaEnquiryView.vue';


const rmaRoutes = [
    {
        path: '/rma',
        name: 'rma',
        component: RmaHomeView,
    },

    {
        path: '/rma-id-cage',
        name: 'rma-id-cage',
        component: RmaGetIdForCageView,
    },

    {
        path: '/rma-id-status',
        name: 'rma-id-status',
        component: RmaGetIdForStatusView,
    },

    {
        path: '/rma-id-enquiry',
        name: 'rma-id-enquiry',
        component: RmaGetIdForEnquiryView,
    },

    {
        path: '/rma-cage-change',
        name: 'rma-cage-change',
        component: RmaCageChangeView,
    },

    {
        path: '/rma-status-change',
        name: 'rma-status-change',
        component: RmaStatusChangeView,
    },

    {
        path: '/rma-enquiry',
        name: 'rma-enquiry',
        component: RmaEnquiryView,
    },


];

export default rmaRoutes