import router from '@/router';
import {  Vue, Options } from 'vue-class-component';
import RmaService from '../../services/rma.service';

const rmaService = new RmaService();

export default class RmaGetIdForCage extends Vue{
    
    public rma_id = '';
    public errorMessage = '';
    public current_cage = '';


    mounted() {
        const rma: any = this.$refs.rmaId
        rma.focus()   
      }
 
    
    checkRmaIdForCage(){
        this.$store.state.Loading = true;
        const id = this.rma_id.replace(/^[0\s]*0+/, '').trim()
        rmaService.RmaIdCheck(id).then(
            (response: any) =>{
                this.$store.state.Loading = false;
                const res = response.data;
                this.current_cage = res.data.cage_number
                
				// console.log(res)
                localStorage.setItem('rma_id', id)
                localStorage.setItem('cage_number', this.current_cage)
                router.push('/rma-cage-change')
            },(err: any) => {
                this.$store.state.Loading = false;
                this.errorMessage = 'Invalid RMA ID';
                console.log(this.errorMessage)
              }
        )
    }

}

