import BaseService from './base.service';


export default class AuthenticationService {
    private apiService: any;
    constructor() {
    this.apiService = new BaseService();
    }


    public Login(user: any) {
        return this.apiService.post('mos/mos-login-api/', user);
      }

    public UserExist(user: any){
        return this.apiService.post('mos/mos-user-exist/', user);
    }
}


