

import { Options, Vue } from 'vue-class-component';
import SparePartsGetIdForLocation from '@/modules/spare_parts/components/SparePartsGetIdForLocation.vue';



@Options({
  components: {
    SparePartsGetIdForLocation,
  },
})

export default class SparePartsGetIdForLocationView extends Vue {}

