

import { Options, Vue } from 'vue-class-component';
import SparePartsEnquiry from '@/modules/spare_parts/components/SparePartsEnquiry.vue';



@Options({
  components: {
    SparePartsEnquiry,
  },
})

export default class SparePartsEnquiryView extends Vue {}

